<template>
  <!-- 公告栏 -->
  <div id="announcement" class="b-modal announcementModal">
    <a href="javascript:;" data-fancybox-close  class="m-close">
      <span class="fancybox-close-text" title="关闭">{{$t('Fancbox.close')}}</span>
    </a>
    <div class="modal-inner">
      <h2 class="m-header" data-i18n="BulletinBoard.head">
        {{$t('BulletinBoard.head')}}
      </h2>
    </div>
    <div class="m-body" v-if="showPic && !showNoContent">
       <a-spin :spinning="loading" class="loadingStyle">
        <img :src="contentpic" alt="图片" class="imgcook" v-if="!loading">
      </a-spin>
    </div>
    <div class="m-body" v-html="contenttext" v-if="!showPic && !showNoContent"></div>
    <div class="m-body" v-html="noContent" v-if="showNoContent"></div>
  </div>
</template>

<script>

import { getAnnouncement, getDownloadBase64 } from '@/api'

export default ({
name:"announcement",  
data(){
return {
contenttext: '',
contentpic:'',
showNoContent:false,
noContent:"暂无更新公告",
loading:false
}
},
computed: {
showPic(){
if(this.contentpic){
return true
}else{
return false
}
}
},
created(){
let that = this;
let data = ""
let instance = null
getAnnouncement().then( res => {
if(res&&res.data&&res.data.contenttext || res.data.contentpic){
   this.showNoContent = false
    that.contenttext = res.data.contenttext
    that.contentpic = res.data.contentpic
    if(that.contentpic){
      that.loading = true;
      getDownloadBase64(res.data.contentpic).then(res=>{
       let h = res.name.substring(res.name.lastIndexOf('.') + 1)
       let prefix = that.getPreifx(h);
       data = prefix + res.base64;
       that.contentpic = data
       that.loading = false;             
    })
    }
    if(!instance){
      instance =  $.fancybox.open( 
          $("#announcement"),{
          modal:false,
          beforeClose : function( instance, current, e ) {
            that.$emit("handleAnnouncementClose")
          }
        })
        $("#announcement .fancybox-close-small").hide()
    }    
}else{
    this.showNoContent = true
    $.fancybox.open( 
          $("#announcement"),{
          modal:false
        })
    $("#announcement .fancybox-close-small").hide()
    $.fancybox.close( 
          $("#announcement"),{
          modal:false
        })
}      

})
},
mounted(){

},
methods:{
getPreifx(key){
let res = ""
switch(key){
case "png":
      res = "data:image/png;base64,";
      break;
case "jpg":
      res = "data:image/jpeg;base64,";
      break;
case "jpeg":
      res = "data:image/jpeg;base64,";
      break;
default:
       res = "data:image/jpeg;base64,";
      break;
}
return res
},
}
})
</script>

<style scoped>
.b-body{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
overflow-x: hidden;
}
.b-body::-webkit-scrollbar {  
display: none;
}
.m-body {
overflow-x: auto;
}
.m-body::-webkit-scrollbar {
width:4px;
}
/* 滚动槽 */
.m-body::-webkit-scrollbar-track {
-webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
border-radius:10px;
}
/* 滚动条滑块 */
.m-body::-webkit-scrollbar-thumb {
border-radius:10px;
background:rgba(0,0,0,0.1);
-webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
.m-body::-webkit-scrollbar-thumb:window-inactive {
background:rgba(0,0,0,0.3);
}
.imgcook {
display: block;
margin: 0 auto;
max-width: 100%;
}
.loadingStyle{
text-align:center;
}
</style>
